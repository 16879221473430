import React, { useEffect } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/navigation/navigation";
import Home from "./pages/home.js";
import Measurement from "./pages/measurement.js";
import Profile from "./pages/profile.js";
import Analytics from "./pages/analytics.js";
import Error from "./pages/error.js";
import { appStyles } from "./App.styles.js";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useKeycloak } from "@react-keycloak/web";

const Styles = styled.div`
  ${appStyles}
`;

function App() {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Styles>
      <Container fluid>
        <Router>
          <Row>
            <Col xs={12} md={1}>
              <Navigation authenticated={keycloak.authenticated}></Navigation>
            </Col>
            <Col xs={12} md={11}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/measurements/:id" element={<Measurement />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <footer hidden>Metrix Cloud copyright</footer>
            </Col>
          </Row>
        </Router>
      </Container>
    </Styles>
  );
}

export default App;
