import React from "react";
// import React, { useState, useEffect } from "react";
// import AnalyticsAverages from "../components/analytics/averages";
// import AnalyticsGraph from "../components/analytics/graph";
// import BubbleChart from "../components/analytics/bubble-chart";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const Styles = styled.div`
  margin-left: 100px;

  .timeframe-filter {
    text-align: center;
    margin-top: 30px;
  }

  .button-timeframe {
    border-radius: 12px;
    border: solid 1px transparent;
    padding: 0 10px;
    margin: 0 10px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    outline: solid 2px rgba(227, 113, 59, 0);
    transition: box-shadow 250ms ease-in, outline 200ms ease;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }

    &:focus {
      box-shadow: 0 5px 15px rgba(227, 113, 59, 0.6);
    }

    &:focus-visible,
    &[active] {
      outline: solid 2px rgba(227, 113, 59, 1);
    }
  }
`;

function Analytics() {
  //   const [startDate, setStartDate] = useState(new Date());
  //   const [endDate, setEndDate] = useState(new Date());
  //   const [data, setData] = useState(false);

  //   async function getData() {
  //     const res = await fetch(
  //       "https://cloud.metrilus.de/api/v3/measurements/?start=2020-04-01"
  //     );
  //     const data = await res.json();
  //     setData(data);
  //   }

  //   useEffect(() => {
  //     getData();

  //     document.body.setAttribute("page", "analytics");

  //     return () => {
  //       document.body.removeAttribute("page");
  //     };
  //   }, []);

  //   const dateCallback = (date, inputStartDate) => {
  //     if (inputStartDate) {
  //       setStartDate(date);
  //     } else {
  //       setEndDate(date);
  //     }
  //   };

  return (
    <Styles>
      <div>
        <header>
          <section>
            <h2>Search by Timeframe</h2>
            <div className="timeframe-filter">
              <button className="button-timeframe" active="true">
                Today
              </button>
              <button className="button-timeframe">Last Week</button>
              <button className="button-timeframe">Last Month</button>
              <button className="button-timeframe">Last Quarter</button>
              <button className="button-timeframe">This Year</button>
            </div>
          </section>
          <section>
            <div className="date-filter">
              <h2>Search by Date</h2>
              <div className="date-filter__from">
                <h3>From</h3>
                {/* <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={startDate}
                  onChange={(date) => dateCallback(date, true)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                /> */}
              </div>
              <div className="date-filter__till">
                <h3>Till</h3>
                {/* <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={endDate}
                  onChange={(date) => dateCallback(date, false)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                /> */}
              </div>
            </div>
          </section>
        </header>
        <h1>Analytics Page</h1>
        {/* <BubbleChart data={data}></BubbleChart> */}
        {/* <AnalyticsGraph
          data={data}
          dataKeyX="timestamp"
          dataKeyY="height"
          title="Timebased height measurements"
        /> */}
        {/* <AnalyticsAverages
          data={data}
          dataKey="height"
          title="height measurements"
        /> */}
        {/* <AnalyticsAverages
          data={data}
          dataKey="weight"
          title="weight measurements"
        /> */}
      </div>
    </Styles>
  );
}

export default Analytics;
