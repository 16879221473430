import Keycloak from "keycloak-js";
const { REACT_APP_ENV, REACT_APP_ENV_AUTH } = process.env;

const keycloak = new Keycloak({
  realm: "metrilus",
  url: "https://auth.metrilus.de/",
  sslRequired: "external",
  resource: "metrix-cloud",
  publicClient: true,
  confidentialPort: 0,
  verifyTokenAudience: true,
  useResourceRoleMappings: true,
  onLoad: "check-sso",
  authority: REACT_APP_ENV_AUTH,
  clientId: "metrix-cloud",
  knownAuthorities: [REACT_APP_ENV_AUTH],
  redirectUri: REACT_APP_ENV,
  postLogoutRedirectUri: REACT_APP_ENV,
});

export default keycloak;
