import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import {
  exportToCsv,
  exportToXlsx,
  exportToPdf,
} from "../components/export/export-table-data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchId from "../components/id-search/id-search";
import fetchWithAuth from "../components/fetch-with-auth/fetch-with-auth";
import { useKeycloak } from "@react-keycloak/web";
import { appStyles } from "./home.styles.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Styles = styled.div`
  ${appStyles}
`;

function openDetails(url, title) {
  const windowSelection = window.open(
    url,
    title,
    "width=500,height=600,status=yes,scrollbars=yes,resizable=yes"
  );
  windowSelection.focus();
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`${min}`}
        style={{
          width: "50%",
        }}
      />
      <input
        value={filterValue[1] || ""}
        type="number"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`${max}`}
        style={{
          width: "50%",
        }}
      />
    </div>
  );
}

function Table({ columns, data, startDate, endDate }) {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 30 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // const exportDataFn = ({ detail }) => {
    //   exportData(detail.format, detail.allData);
    // };
    // added multiple times because of pagination
    // document.addEventListener("exportData", exportDataFn);
    // return () => {
    //   document.removeEventListener("exportData", exportDataFn);
    // };
  });

  return (
    <>
      {data.length > 0 ? (
        <div className="table-wrapper">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="column-sorting">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      {column.render("Header")}
                      {column.unit ? <span>({column.unit})</span> : ""}
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.className,
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    <td>
                      <button
                        onClick={() =>
                          openDetails(
                            `/measurements/${row.values.id}`,
                            row.values.id
                          )
                        }
                      >
                        👁
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table--no-data">
          {`No measurements found from ${("0" + startDate.getDate()).slice(
            -2
          )}.${("0" + (startDate.getMonth() + 1)).slice(
            -2
          )}.${startDate.getFullYear()} to ${("0" + endDate.getDate()).slice(
            -2
          )}.${("0" + (endDate.getMonth() + 1)).slice(
            -2
          )}.${endDate.getFullYear()}. Please change
            the date above.`}
        </div>
      )}

      {pageOptions.length > 0 ? (
        <div className="pagination">
          <div className="btn-group" role="group" aria-label="pagination">
            <button
              className="btn btn-primary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              prev
            </button>
            <span className="page-index btn">
              <strong>{pageIndex + 1}</strong> of {pageOptions.length}
            </span>
            <button
              className="btn btn-primary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              next
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="export-buttons">
        {rows.length > 0 ? (
          <DropdownButton
            id="dropdown-basic-button"
            title={`export ${rows.length} measurements`}
          >
            <Dropdown.Item onClick={() => exportToPdf(rows, columns)}>
              pdf
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportToCsv(rows)}>csv</Dropdown.Item>
            <Dropdown.Item onClick={() => exportToXlsx(rows)}>
              xlsx
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function Home() {
  // currently no measurements are available on initial load, because measurements from 'today' are available tomorrow
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { keycloak, initialized } = useKeycloak();
  const [data, setData] = useState(null);

  const dateFn = (date) => {
    date = new Date(date);
    return `${("0" + date.getDate()).slice(-2)}.${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}.${date.getFullYear()}`;
  };
  const timeFn = (date) => {
    date = new Date(date);
    return `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
  };

  const dateCallback = (date, inputStartDate) => {
    if (inputStartDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  useEffect(() => {
    if (startDate.length === 0 && endDate.length === 0) {
      return false;
    }
    const startDateDay = ("0" + startDate.getDate()).slice(-2);
    const startDateMonth = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const startDateYear = startDate.getFullYear();
    // needed to include the results from the endDate as well
    const endDatePlus1 = new Date(endDate);
    endDatePlus1.setDate(endDatePlus1.getDate() + 1);
    const endDateDay = ("0" + endDatePlus1.getDate()).slice(-2);
    const endDateMonth = ("0" + (endDatePlus1.getMonth() + 1)).slice(-2);
    const endDateYear = endDatePlus1.getFullYear();

    const query = `start=${startDateYear}-${startDateMonth}-${startDateDay}&end=${endDateYear}-${endDateMonth}-${endDateDay}`;
    const fetchData = async () => {
      const response = await fetchWithAuth(
        `https://cloud.metrilus.de/api/v3/measurements/?${query}`,
        {},
        keycloak
      );
      const data = await response;
      setData(data);
    };
    fetchData();
  }, [startDate, endDate]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filter: "String",
        disableSortBy: true,
      },
      {
        Header: "Date",
        accessor: (row) => dateFn(row.timestamp),
        className: "right-aligned",
      },
      {
        Header: "Time",
        accessor: (row) => timeFn(row.timestamp),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "Length",
        unit: "cm",
        accessor: (row) => (row.length * 100).toFixed(2),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "Width",
        unit: "cm",
        accessor: (row) => (row.width * 100).toFixed(2),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "Height",
        unit: "cm",
        accessor: (row) => (row.height * 100).toFixed(2),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "Weight",
        unit: "gr",
        accessor: (row) => Math.round(row.weight * 1000),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "Volume",
        unit: "L3",
        accessor: (row) =>
          (
            (row.length * 100 * (row.width * 100) * (row.height * 100)) /
            5000
          ).toFixed(2),
        Filter: NumberRangeColumnFilter,
        filter: "between",
        className: "right-aligned",
      },
      {
        Header: "System ID",
        accessor: "systemId",
        Filter: SelectColumnFilter,
        filter: "includes",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <Styles>
      <section className="app" role="main">
        <header>
          <Row>
            <Col xs={12} md={6}>
              <section>
                <h2>Search by ID</h2>
                <div className="search--global">
                  <SearchId placeholder="Search for ID"></SearchId>
                </div>
              </section>
            </Col>
            <Col>
              <Row>
                <h2>Search by Date</h2>
                <Col className="date-filter__from">
                  <InputGroup size="m" className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      From
                    </InputGroup.Text>
                    <DatePicker
                      dateFormat={"dd.MM.yyyy"}
                      selected={startDate}
                      onChange={(date) => dateCallback(date, true)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="form-control bs-input-overlay"
                    />
                  </InputGroup>
                </Col>
                <Col className="date-filter__till">
                  <InputGroup size="m" className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-sm">
                      Till
                    </InputGroup.Text>
                    <DatePicker
                      dateFormat={"dd.MM.yyyy"}
                      selected={endDate}
                      onChange={(date) => dateCallback(date, false)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      className="form-control bs-input-overlay"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </header>
        {data ? (
          <Table
            columns={columns}
            data={data}
            startDate={startDate}
            endDate={endDate}
          />
        ) : (
          "loading..."
        )}
      </section>
    </Styles>
  );
}

export default Home;
