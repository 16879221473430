async function fetchWithAuth(url, options = {}, keycloak) {
  const accessToken = await keycloak.updateToken(300);
  //   console.log("kc fetch access token", accessToken);
  if (keycloak.token) {
    // console.log("keycloak token", keycloak.token);
    const headers = {
      Authorization: `Bearer ${keycloak.token}`,
      "Content-Type": "application/json",
      ...options.headers,
    };
    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      throw new Error(`Fetch error: ${response.status}`);
    }
    return await response.json();
  } else {
    throw new Error("Failed to refresh access token");
  }
}

export default fetchWithAuth;
