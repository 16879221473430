export const idSearchStyles = `
  position: relative;

  &:focus {
    .search_icon {
        opacity: 0;
    }
  }

  .search_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    fill: grey;
    opacity: 1;
    transition: opacity 250ms ease;
  }
`;
