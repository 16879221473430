import React from "react";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import {
  exportToCsv,
  exportToXlsx,
  exportToPdf,
} from "../export/export-measurement-data";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Styles = styled.div`
  position: relative;
  margin: 0 20px 20px 100px;

  @media (max-width: 768px) {
    margin: 0 20px 20px 20px;
  }

  h2 {
    margin-top: 30px;
  }

  table {
    font-family: monospace;
    margin: 0 !important;
  }

  table th span {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.6rem;
  }

  table thead {
    display: none;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.2);
    margin-top: 16px;
  }

  .export-buttons {
    margin-top: 16px;
  }
`;

function MeasurementDetails(props) {
  const dateFn = (date) => {
    date = new Date(date);
    return `${("0" + date.getDate()).slice(-2)}.${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}.${date.getFullYear()}`;
  };
  const timeFn = (date) => {
    date = new Date(date);
    return `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
  };

  return (
    <Styles>
      <div className="measurement" id={props.id}>
        <h2>{props.id}</h2>
        <Table id="measurement-table" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                Length <span>(cm)</span>
              </th>
              <td>{(props.data.length * 100).toFixed(2)}</td>
            </tr>
            <tr>
              <th>
                Width <span>(cm)</span>
              </th>
              <td>{(props.data.width * 100).toFixed(2)}</td>
            </tr>
            <tr>
              <th>
                Height <span>(cm)</span>
              </th>
              <td>{(props.data.height * 100).toFixed(2)}</td>
            </tr>
            <tr>
              <th>
                Weight <span>(gr)</span>
              </th>
              <td>{Math.round(props.data.weight * 1000)}</td>
            </tr>
            <tr>
              <th>
                Volumen <span>(L3)</span>
              </th>
              <td>
                {(
                  (props.data.length *
                    100 *
                    (props.data.width * 100) *
                    (props.data.height * 100)) /
                  5000
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <th>System Id</th>
              <td>{props.data.systemId}</td>
            </tr>
            <tr>
              <th>Creation Date</th>
              <td>
                {dateFn(props.data.timestamp)} {timeFn(props.data.timestamp)}
              </td>
            </tr>
          </tbody>
        </Table>
        {props.data.images.length > 0 ? (
          props.data.images.map((img, i) => (
            <img
              src={`https://cloud.metrilus.de/api/${img}`}
              alt={props.id}
              key={i}
            />
          ))
        ) : (
          <p>No photos available</p>
        )}
      </div>

      <div className="export-buttons">
        <DropdownButton id="dropdown-basic-button" title={`export measurement`}>
          <Dropdown.Item
            onClick={() => exportToPdf(props, "#measurement-table")}
          >
            pdf
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              exportToCsv(props, document.querySelectorAll("table tr"))
            }
          >
            csv
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              exportToXlsx(props, document.querySelectorAll("table tr"))
            }
          >
            xlsx
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </Styles>
  );
}

export default MeasurementDetails;
