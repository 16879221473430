import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Nav from "react-bootstrap/Nav";

function Navigation({ authenticated }) {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Nav>
      <Nav.Link as={Link} className="logo-link" to="/">
        <svg
          viewBox="0 0 130 150"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
        >
          <path
            d="M95.603 100.256l-29.42 19.613v28.68l29.42-19.614"
            fill="#918c91"
            fillRule="nonzero"
          />
          <path
            d="M128.694 78.196l-29.42 19.613v28.679l29.42-19.614m-29.42-42.156v28.679l29.42-19.614V45.104M95.603 67.165l-29.42 19.613v28.679l29.42-19.613"
            fill="#f46926"
            fillRule="nonzero"
          />
          <path
            d="M68.019 41.675l29.42 19.613 29.421-19.613-29.421-19.614m-62.511-2.447l29.419 19.613 29.421-19.613L64.347 0m-33.09 61.288l29.42-19.613-29.42-19.614L1.836 41.675m33.092 22.06l29.419 19.614 29.421-19.614-29.421-19.613"
            fill="#918c91"
            fillRule="nonzero"
          />
          <path
            d="M29.42 93.397V64.718L0 45.104v28.679m29.42 52.705V97.809L0 78.196v28.678"
            fill="#f46926"
            fillRule="nonzero"
          />
          <path
            d="M62.512 119.869l-29.42-19.613v28.679l29.42 19.614"
            fill="#918c91"
            fillRule="nonzero"
          />
          <path
            d="M33.092 67.165v28.679l29.42 19.613V86.778"
            fill="#f46926"
            fillRule="nonzero"
          />
        </svg>
      </Nav.Link>

      <Nav.Link as={Link} className="" to="/analytics">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <g
            stroke="none"
            strokeWidth="1"
            fill="currentColor"
            fillRule="nonzero"
          >
            <path
              d="M3.218 11.068h-1.92a.305.305 0 0 0-.298.309v3.311c0 .17.134.31.298.31h1.923a.305.305 0 0 0 .298-.31v-3.311a.307.307 0 0 0-.3-.31Zm-.298 3.314H1.596v-2.696h1.327v2.696H2.92ZM7.046 15a.305.305 0 0 0 .298-.31V6.484c0-.17-.134-.31-.298-.31H5.123a.305.305 0 0 0-.298.31v8.204c0 .17.134.31.298.31h1.923V15ZM5.424 6.792h1.327v7.587H5.424V6.792ZM10.874 15a.305.305 0 0 0 .298-.31V9.188c0-.17-.134-.309-.298-.309h-1.92a.305.305 0 0 0-.298.31v5.503c0 .17.134.309.298.309h1.92ZM9.252 9.497h1.327v4.884H9.252V9.497ZM15 14.69V4.309c0-.17-.134-.309-.298-.309h-1.923a.305.305 0 0 0-.298.31v10.38c0 .17.134.31.298.31h1.923a.305.305 0 0 0 .298-.309Zm-.596-.31h-1.327V4.62h1.327v9.763Z"
              id="Shape"
            />
            <path
              d="M2.34 8c.74 0 1.34-.569 1.34-1.265a1.22 1.22 0 0 0-.176-.619L5.34 4.551a1.38 1.38 0 0 0 1.774-.197l1.49.772a1.135 1.135 0 0 0-.058.346c0 .7.602 1.266 1.34 1.266.737 0 1.34-.57 1.34-1.266 0-.224-.068-.432-.177-.615l1.837-1.563c.218.148.485.236.773.236.74 0 1.34-.568 1.34-1.265C15 1.57 14.398 1 13.66 1c-.738 0-1.34.569-1.34 1.265 0 .225.068.433.176.616L10.66 4.443a1.38 1.38 0 0 0-1.774.197l-1.49-.771c.035-.111.058-.225.058-.347 0-.7-.602-1.265-1.34-1.265-.737 0-1.34.569-1.34 1.265 0 .225.068.433.177.619L3.113 5.706a1.38 1.38 0 0 0-.773-.236C1.6 5.47 1 6.038 1 6.735 1 7.43 1.603 8 2.34 8Zm11.32-6.45c.414 0 .752.318.752.71 0 .39-.338.71-.752.71s-.752-.32-.752-.71c0-.392.338-.71.752-.71ZM9.887 4.76c.414 0 .752.318.752.71 0 .39-.338.71-.752.71-.415 0-.753-.32-.753-.71 0-.392.338-.71.753-.71ZM6.113 2.805c.415 0 .753.32.753.71 0 .392-.338.71-.753.71-.414 0-.752-.318-.752-.71 0-.39.338-.71.752-.71ZM2.34 6.022c.414 0 .752.319.752.71 0 .391-.338.71-.752.71s-.752-.319-.752-.71c0-.391.338-.71.752-.71Z"
              id="Shape"
            />
          </g>
        </svg>
      </Nav.Link>

      {authenticated && (
        <Nav.Link as={Link} className="" to="/profile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
          </svg>
        </Nav.Link>
      )}
    </Nav>
  );
}

export default Navigation;
