import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { idSearchStyles } from "./id-search.styles.js";
import fetchWithAuth from "../fetch-with-auth/fetch-with-auth";

const Styles = styled.div`
  ${idSearchStyles}
`;

function SearchId(props) {
  const SearchIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      className="search_icon"
    >
      <g>
        <path d="M18.853,17.438l-3.604-3.604c-0.075-0.075-0.166-0.127-0.267-0.156C15.621,12.781,16,11.686,16,10.5   C16,7.463,13.537,5,10.5,5S5,7.463,5,10.5S7.463,16,10.5,16c1.186,0,2.281-0.379,3.18-1.018c0.028,0.101,0.08,0.191,0.155,0.267   l3.604,3.604c0.301,0.301,0.858,0.227,1.249-0.165C19.079,18.297,19.153,17.739,18.853,17.438z M10.5,14C8.568,14,7,12.432,7,10.5   S8.568,7,10.5,7S14,8.568,14,10.5S12.432,14,10.5,14z" />
      </g>
    </svg>
  );

  const [query, setQuery] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { keycloak, initialized } = useKeycloak();

  const handleSearch = (query) => {
    setIsLoading(true);
    setQuery(query);
  };

  useEffect(() => {
    setIsLoading(false);

    const fetchData = async () => {
      const response = await fetchWithAuth(
        `https://cloud.metrilus.de/api/v3/suggestions/?query=${query}`,
        {},
        keycloak
      );
      const data = await response;
      setOptions(data);
    };
    fetchData();
  }, [query]);

  function openDetails(url, title) {
    const windowSelection = window.open(
      url,
      title,
      "width=500,height=400,status=yes,scrollbars=yes,resizable=yes"
    );
    windowSelection.focus();
  }

  return (
    <Styles>
      <AsyncTypeahead
        id="async-id-search"
        isLoading={isLoading}
        labelKey={props.placeholder}
        minLength={2}
        onSearch={handleSearch}
        options={options}
        placeholder={props.placeholder}
        renderMenuItemChildren={(option) => (
          <Fragment>
            <span
              onClick={() => openDetails(`/measurements/${option}`, option)}
            >
              {option}
            </span>
          </Fragment>
        )}
      />
      {SearchIcon()}
    </Styles>
  );
}

export default SearchId;
