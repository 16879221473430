import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { printHeaderImg } from "./base64-images";

function fileNameFn() {
  const date = new Date();
  const dateString = `${
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2)
  }`;
  return dateString;
}

export function exportToCsv(rows) {
  const header = Object.keys(rows[0].values);
  const csv = [
    header.join(","),
    ...rows.map((row) =>
      header.map((fieldName) => row.values[fieldName]).join(",")
    ),
  ].join("\n");

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  saveAs(blob, `${fileNameFn()}_metrix-cloud-export.csv`);
}

export function exportToXlsx(rows) {
  const data = rows.map((row) => row.values);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Table");
  const xlsxFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([xlsxFile], { type: "application/vnd.ms-excel" });
  saveAs(blob, `${fileNameFn()}_metrix-cloud-export.xlsx`);
}

export function exportToPdf(rows, columns) {
  const doc = new jsPDF();
  const headerNames = columns.map(
    (column) => `${column.Header} ${column.unit ? `(${column.unit})` : ""}`
  );

  const dataRaw = rows.map((row) => row.values);
  const dataRows = dataRaw.map((item) => Object.values(item));

  doc.autoTable({
    head: [headerNames],
    headStyles: {
      fillColor: [227, 113, 59],
      cellWidth: "wrap",
    },
    body: dataRows,
    theme: "grid",
    margin: { top: 40, left: 10, right: 10 },
    styles: { font: "courier", fontSize: 8 },
  });

  const currentDate = new Date();
  const pages = doc.internal.getNumberOfPages();
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width;
  const pageHeight = pageSize.height;

  for (let pageIndex = 1; pageIndex < pages + 1; pageIndex++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 5; //Can be fixed number

    doc.addImage(
      printHeaderImg,
      "PNG",
      pageWidth * 0.05,
      pageWidth * 0.05,
      pageWidth * 0.9,
      pageWidth * 0.9 * 0.12672733
    );
    doc.setPage(pageIndex);
    doc
      .setFontSize(8)
      .text(`${pageIndex} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      })
      .text(
        `© Metrilus GmbH ${currentDate.getFullYear()} - All Rights Reserved.`,
        pageWidth * 0.05,
        verticalPos,
        {
          align: "left",
        }
      )
      .text(
        `Created at ${("0" + currentDate.getDate()).slice(-2)}.${(
          "0" +
          (currentDate.getMonth() + 1)
        ).slice(-2)}.${currentDate.getFullYear()}`,
        pageWidth - pageWidth * 0.05,
        verticalPos,
        {
          align: "right",
        }
      );
  }

  doc.save(`${fileNameFn()}_metrix-cloud-export.pdf`);
}
