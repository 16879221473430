export const appStyles = `
.app {
    margin-top: 20px;
}

.nav {
    margin-top: 10px;
}

a.nav-link {
    text-decoration: none;
    cursor: pointer;
    color: rgb(144, 140, 145);
    transition: all 250ms ease;

    svg {
      width: 100%;
      height: 100%;
      min-width: 36px;
      min-height: 36px;
      max-width: 42px;
      max-height: 42px;

      @media (max-width: 768px) {
          max-width: 36px;
          max-height: 36px;
        }
    }

    &:hover {
      color: rgb(227, 113, 59);
    }
  }

`;
