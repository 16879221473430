import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

const keycloakProviderInitConfig = {
  onLoad: "login-required",
};

const keycloakProviderConfig = {
  initOptions: keycloakProviderInitConfig,
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ReactKeycloakProvider authClient={keycloak} {...keycloakProviderConfig}>
    <App />
  </ReactKeycloakProvider>
);
