import React from "react";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";

const Styles = styled.div`
  margin-left: 100px;
`;

function Profile() {
  const { keycloak, initialized } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout();
  };

  if (!initialized) {
    return <div>Loading...</div>;
  }

  if (!keycloak.authenticated) {
    return <p>Please log in to access this feature.</p>;
  }

  const { given_name, family_name } = keycloak.tokenParsed;

  return (
    <Styles>
      <div>
        <h1>Profile</h1>
        <p>
          Name: {given_name} {family_name}
        </p>
        <p>Settings:</p>
        <ul>
          <li>Setting 1</li>
          <li>Setting 2</li>
          <li>Setting 3</li>
        </ul>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </Styles>
  );
}

export default Profile;
