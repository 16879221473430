import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { printHeaderImg } from "./base64-images";

function fileNameFn() {
  const date = new Date();
  const dateString = `${
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2)
  }`;
  return dateString;
}

export function exportToCsv(props, htmlTableTr) {
  const data = [];

  for (let i = 0; i < htmlTableTr.length; i++) {
    const row = [];
    const cols = htmlTableTr[i].querySelectorAll("td, th");

    for (let j = 0; j < cols.length; j++) {
      row.push(cols[j].innerText);
    }

    data.push(row.join(","));
  }

  const csvFile = new Blob([data.join("\n")], { type: "text/csv" });
  saveAs(csvFile, `${fileNameFn()}_${props.id}_metrix-cloud-export.csv`);
}

export function exportToXlsx(props, htmlTableTr) {
  const data = [];

  for (let i = 0; i < htmlTableTr.length; i++) {
    const row = [];
    const cols = htmlTableTr[i].querySelectorAll("td, th");

    for (let j = 0; j < cols.length; j++) {
      row.push(cols[j].innerText);
    }

    data.push(row);
  }

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const xlsxFile = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const buffer = new ArrayBuffer(xlsxFile.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < xlsxFile.length; i++) {
    view[i] = xlsxFile.charCodeAt(i) & 0xff;
  }
  const xlsxBlob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(xlsxBlob, `${fileNameFn()}_${props.id}_metrix-cloud-export.xlsx`);
}

export function exportToPdf(props, htmlTable) {
  const doc = new jsPDF();

  doc.setFontSize(16).text(props.id, 10, 44, {
    align: "left",
    charSpace: 0.2,
  });

  doc.autoTable({
    html: htmlTable,
    theme: "grid",
    headStyles: {
      fillColor: [227, 113, 59],
      cellWidth: "wrap",
    },
    margin: { top: 50, left: 10, right: 10 },
    styles: { font: "courier", fontSize: 8 },
  });

  const currentDate = new Date();
  const pages = doc.internal.getNumberOfPages();
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width;
  const pageHeight = pageSize.height;

  for (let pageIndex = 1; pageIndex < pages + 1; pageIndex++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 5; //Can be fixed number

    if (props.data.images.length > 0) {
      doc.addImage(
        printHeaderImg,
        "PNG",
        pageWidth * 0.05,
        pageWidth * 0.05,
        pageWidth * 0.9,
        pageWidth * 0.9 * 0.12672733
      );

      const imageSrc = `https://cloud.metrilus.de/api/${props.data.images[0]}`;
      const tableHeight = doc.previousAutoTable.finalY;
      const imgProps = doc.getImageProperties(imageSrc);
      const maxWidth = 150;
      const imageHeight = imgProps.height * (maxWidth / imgProps.width);
      doc.addImage(
        imageSrc,
        "PNG",
        10,
        tableHeight + 7,
        maxWidth,
        imageHeight,
        "",
        "FAST"
      );
    }

    doc.setPage(pageIndex);
    doc
      .setFontSize(8)
      .text(`${pageIndex} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      })
      .text(
        `© Metrilus GmbH ${currentDate.getFullYear()} - All Rights Reserved.`,
        pageWidth * 0.05,
        verticalPos,
        {
          align: "left",
        }
      )
      .text(
        `Created at ${("0" + currentDate.getDate()).slice(-2)}.${(
          "0" +
          (currentDate.getMonth() + 1)
        ).slice(-2)}.${currentDate.getFullYear()}`,
        pageWidth - pageWidth * 0.05,
        verticalPos,
        {
          align: "right",
        }
      );
  }

  doc.save(`${fileNameFn()}_${props.id}_metrix-cloud-export.pdf`);
}
