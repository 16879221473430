import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  margin-left: 100px;
`;

function Error() {
  return (
    <Styles>
      <div>This page is not found</div>
    </Styles>
  );
}

export default Error;
