import React, { useEffect, useState } from "react";
import MeasurementDetails from "../components/measurement/measurement-details.js";
import { useParams } from "react-router-dom";
import fetchWithAuth from "../components/fetch-with-auth/fetch-with-auth";
import { useKeycloak } from "@react-keycloak/web";

function Measurement() {
  const { id } = useParams();
  console.log("id", id);
  const { keycloak, initialized } = useKeycloak();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth(
        `https://cloud.metrilus.de/api/v3/measurements/${id}`,
        {},
        keycloak
      );
      const data = await response;
      setData(data);
    };
    fetchData();
  }, []);

  return (
    <div>
      {!data ? "Loading..." : <MeasurementDetails id={id} data={data} />}
    </div>
  );
}

export default Measurement;
