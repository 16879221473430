export const appStyles = `

.react-datepicker-popper {
    z-index: 4;
}

.react-datepicker-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.bs-input-overlay {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table-wrapper {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    background: transparent;
    overflow-x: auto;
    
    @media (max-width: 768px) {
        background: white;
        margin-left: 0;
    }

    @media (min-width: 769px) {
        margin-bottom: 80px;
        padding: 20px;
    }
  }

  [loading='false'] & {
    table {
      opacity: 1;
    }
  }

  [loading='true'] {
    table {
      opacity: 0;
    }
  }

  table {
    border-spacing: 0;
    margin-left: 0;
    font-family: monospace;
    transition: opacity 350ms ease-out;

    tr {
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 80ms ease;

      :last-child {
        td {
          border-bottom: 0;
        }
      }

      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    thead {
      th {
        position: relative;
        position: sticky;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(6px);

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
      tr {
        color: white;

        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.7rem 0.5rem;
      /* border-bottom: 1px solid black; */
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      :last-child {
        border-right: 0;
      }
    }

    th > span {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.6rem;
      margin-left: 5px;
    }

    th > div {
      margin-top: 6px;
      margin-bottom: 2px;
    }

    td.right-aligned {
      text-align: right;
    }

    td > button {
        background: transparent;
        border: none;
    }
  }

  .column-sorting {
    position: absolute;
    top: 9px;
    left: 3px;
    color: rgba(255, 255, 255, 1);
  }

  .pagination {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 10;

    @media (max-width: 768px) {
        position: relative;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    .btn-group {
        margin: 0 auto;
    }

    .page-index {
        pointer-events: none;
        margin: 0;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(3px);

        &:hover {
            border: transparent;
        }
    }
  }

  .table--no-data {
    width: 100%;
    max-width: 460px;
    margin: 5vw auto;
  }

  .export-buttons {
    position: fixed;
    pointer-events: none;
    bottom: 0;
    right: 0;
    text-align: right;
    z-index: 20;

    .dropdown {
        pointer-events: auto;
        margin: 0 20px 20px 0;
    }
  }

`;
